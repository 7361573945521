html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: #ededed;
  color: #555;
}

button {
  touch-action: manipulation;
}

.nav-section {
  background-color: #333;
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 100;
  padding: 15px;
  color: #fff;
  overflow: hidden;
}

#top-nav {
  top: 0;
}

#bottom-nav {
  bottom: 0;
}

#top-nav .btn-text-left,
#top-nav .btn-text-right {
  margin: 0;
}

.page-content {
  margin: 100px 0;
  padding-bottom: 150px !important;
}

.btn-default {
  background-color: #008c8c;
  color: #ffffff;
  border-radius: 4px;
  height: 100%;
  border: 0;
  transition: 0.1s;
  text-align: center;
  padding: 0 15px;
  cursor: pointer;
}

.btn-default:hover,
.btn-default:active {
  background-color: #006c6c;
}

.btn-default:focus {
  outline: none;
}

.btn-default .btn-text {
  font-size: 28px;
  font-weight: bold;
}

.btn-light {
  background-color: #fff;
  color: #008c8c;
}

.btn-light:hover,
.btn-light:active {
  background-color: #ccc;
}

.btn-text-left {
  margin-right: 10px;
}

.btn-text-right {
  margin-left: 10px;
}

.btn-add {
  width: 60px;
  height: 60px;
  padding: 8px;
  float: right;
}

.plus::before {
  font-size: 5em;
  font-weight: 600;
  line-height: 0;
  content: "+";
  display: inline-block;
  position: relative;
  top: 13px;
  color: #fff;
}

.chevron::before {
  border-style: solid;
  border-width: 0.75em 0.75em 0 0;
  content: "";
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  position: relative;
  top: 0;
  vertical-align: top;
  color: #fff;
}

.chevron.chevron-right:before {
  left: -5px;
  transform: rotate(45deg);
}

.chevron.chevron-left:before {
  left: 5px;
  transform: rotate(-135deg);
}

.text-center {
  text-align: center;
}

a {
  color: #555;
  text-decoration: none;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.div-half {
  width: 50%;
  display: inline-block;
}

.no-margin {
  margin: 0;
}

.no-vis {
  visibility: hidden;
}

.no-display {
  display: none;
}

#bottom-nav .btn-default {
  min-width: 200px;
  white-space: nowrap;
}

#top-nav.collection-list,
#bottom-nav.collection-list {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
}

.nav-btn-square {
  width: 70px;
}

.collection-date {
  padding: 5px 15px;
}

.collection-date span {
  font-size: 2.4em;
  font-weight: bold;
}

#bottom-nav.collection-list img {
  height: 100%;
}

.app-info {
  margin-right: auto;
  margin-left: 5px;
  overflow: hidden;
}

.app-info h2,
.app-info p {
  margin: 0;
}

.app-info p {
  font-size: 0.9em;
  color: #ddd;
}

#collection-list-rows,
#collection-details,
#disposal-details {
  padding: 15px;
}

.collection-row {
  background-color: #fff;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 5px;
  /* font-size: 26px; */
  font-size: 1.3em;
  font-weight: bold;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
}

.collection-row.collection-complete {
  background-color: #ddd;
}

.collection-row p {
  margin: 0;
}

.collection-row .col-postcode {
  margin-left: auto;
  padding: 0 40px;
  min-width: 150px;
  width: 150px;
}

.collection-row .col-icon {
  width: 30px;
}

.collection-row .col-icon .chevron::before {
  color: #008c8c;
  font-size: initial;
  border-width: 0.6em 0.6em 0 0;
  height: 1em;
  width: 1em;
  top: 8px;
}

.collection-row .col-icon .chevron.tick::before {
  transform: rotate(135deg);
  border-width: 0.6em 0.5em 0 0;
  height: 0.7em;
  width: 1.4em;
}

.collection-row.collection-row-options {
  background: transparent;
  padding: 0;
  margin: 0;
  height: 70px;
}

.collection-row-options .btn-light {
  min-width: 70px;
  width: 70px;
  fill: #008c8c;
}

.collection-row-options p {
  margin-right: auto;
  padding: 0 15px;
  font-weight: initial;
  line-height: 70px;
  color: #777;
}

#collection-details p {
  font-size: 24px;
  margin: 0 0 2px 0;
}

#collection-details .driver-notes {
  margin: 0 0 10px 0;
  font-size: 18px;
}

.collection-waste-info {
  display: grid;
  grid-template-columns: 20% 1fr 20% 10%;
  margin-bottom: 5px;
  /* font-size: 26px; */
  font-size: 1.3em;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  align-items: center;
  row-gap: 5px;
}

.collection-waste-info-text {
  font-weight: bold;
}

.collection-waste-info input[disabled],
.collection-waste-info select[disabled] {
  background-color: #fafafa;
}

input[type="checkbox"] {
  position: absolute;
  cursor: pointer;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  top: -3px;
  left: -2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 12px;
  height: 24px;
  border: solid #008c8c;
  border-width: 0 10px 8px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 12px;
}

.cwi-collected input,
.cwi-delivered input,
.cwi-weight input,
.disposal-extra input {
  width: 100%;
}

div.cwi-stock select {
  width: 95%;
  text-align: left;
  box-shadow: 0px 0px 4px #bbb;
  outline: none;
}

.cwi-weight input,
.cwi-collected input,
.cwi-delivered input,
.disposal-extra input,
.cwi-stock select {
  max-width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* font-size: 26px; */
  font-size: 1em;
  font-weight: bold;
  color: #555;
  font-family: inherit;
  text-align: center;
  padding: 0;
  margin-top: auto;
  height: 40px;
  line-height: 40px;
}

.btn-light.add-waste-item-collection {
  min-width: 70px;
  width: 70px;
  fill: #008c8c;
}

.collection-details-options {
  display: flex;
  justify-content: flex-end;
  height: 70px;
}

#confirm-details-section {
  margin-top: 10px;
}

#confirm-details-section h3 {
  margin-bottom: 5px;
  margin-top: 0;
}

#confirm-details-section p {
  font-size: initial;
  text-align: initial;
}

.customer-sign {
  display: flex;
  margin: 10px 0;
}

#customer-sign-area {
  background-color: #fefefe;
  border-radius: 4px;
  margin-left: 15px;
  width: 97%;
}

.sign-area-overlay {
  position: relative;
  top: calc(50% - 20px);
  height: 0px;
  display: block;
  font-size: 28px;
  opacity: 0.2;
  text-align: center;
  padding-left: 15px;
}

.driver-comments-area {
  width: calc(100% - 20px);
  min-height: 70px;
  resize: vertical;
  border: 0;
  border-radius: 4px;
  font-size: 20px;
  font-family: inherit;
  padding: 5px 10px;
}

.driver-access-check[type="checkbox"] {
  position: relative;
  margin-right: 50px;
  margin-bottom: 35px;
  height: 28px;
}

.driver-additional p {
  display: inline-block;
  height: 50px;
  line-height: 50px;
}

#disposal-details hr {
  margin: 10px 0;
}

.disposal-location p {
  font-size: 24px;
}

.disposal-item-list {
  margin-top: 10px;
}

.dsp-col {
  text-align: center !important;
}

input.dsp-acc-check[type="checkbox"] {
  top: -13px;
  position: relative;
  right: initial;
}

input.dsp-acc-check[type="checkbox"]:before {
  width: 40px;
  height: 40px;
}

input.dsp-acc-check[type="checkbox"]:checked:after {
  width: 10px;
  height: 20px;
  border-width: 0 7px 7px 0;
  top: 1px;
  left: 11px;
}

.disposal-extra {
  display: flex;
  margin-top: 10px;
}

.disposal-extra input {
  margin-left: 10px;
  width: 100px;
}

.disposal-extra .input-full {
  width: 200px;
}

.disposal-non-haz {
  width: 100%;
}

.dsp-ewc-code {
  /* width:20%; */
  /* grid-column: 1 / -1; */
}

#confirm-details-section .mt-10 {
  margin-top: 10px;
}

.nh-dispose-extra {
  float: right;
}

#customer-sign-area-full {
  background-color: #fff;
}

#cosignor-signature-img {
  background-color: #fff;
  border-radius: 4px;
  width: 97%;
}

#driver-list {
  padding-top: 20px;
  margin-bottom: 0;
}

.driver-row {
  display: block;
  height: 75px;
  min-width: 200px;
  width: 50%;
  margin: 0 auto 20px auto;
  font-size: 1.5em;
  font-weight: 500;
  background-color: #008c8c;
  color: #fff;
  border: 0;
  border-radius: 4px;
}

#bottom-nav .btn-default.driver-switch-btn {
  min-width: auto;
  width: 70px;
}

.collection-date .driver-name-label {
  display: block;
  font-size: 1em;
  font-weight: initial;
  color: #ddd;
  text-align: center;
}

@media (max-width: 796px) {
  .collection-date span {
    font-size: 1.8em;
  }
}

.login-main {
  width: 100%;
  height: 100%;
  background: #e3f1ee;
}

.login-container {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  transition: width 0.2s ease-in;
}

.login-input {
  display: block;
  margin: 20px auto 20px auto;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  color: #555;
  font-family: inherit;
  text-align: center;
  padding: 0;
  margin-top: auto;
  height: 40px;
  line-height: 40px;
}

.login-button {
  display: inline-block;
  font-family: inherit;
  padding: 10px 20px;
  font-size: 20px;
}

.no-display {
  display: none;
}

.margin-tb-ten {
  margin: 10px 0;
}

.collection-waste-info input.input-disabled {
  background-color: #ddd;
}

.waste-delete-btn {
  width: 75%;
  padding: 2px 10px;
  background-color: #8c0000;
}

.waste-delete-btn:hover,
.waste-delete-btn:active {
  background-color: #6c0000;
}

.waste-delete-btn:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: #aaa;
}

.text-red {
  color: red;
}